<template>
  <svg
    class="inline-block self-center overflow-hidden fill-current stroke-current stroke-0 transition-colors"
    :class="[classSize]"
    aria-hidden="true"
  >
    <use :xlink:href="`${symbolDefs}#icon-${name}`" :fill="color" />
  </svg>
</template>

<script setup lang="ts">
import symbolDefs from '~/assets/images/icons/symbol-defs.svg'

type Icon =
  | 'arrow-left-long'
  | 'arrow-left'
  | 'arrow-right-long'
  | 'arrow-right'
  | 'check'
  | 'chevron-filled-down-1'
  | 'chevron-filled-down'
  | 'close'
  | 'facebook-circled'
  | 'facebook'
  | 'growth'
  | 'instagram-circled'
  | 'linkedin-circled'
  | 'linkedin'
  | 'lock'
  | 'loop'
  | 'mail-circled'
  | 'medium'
  | 'menu'
  | 'navichevron-filled-down'
  | 'navichevron-filled-up-navi'
  | 'pen'
  | 'puzzle'
  | 'quote'
  | 'rocket'
  | 'saving-pig'
  | 'sign'
  | 'square-arrow-down'
  | 'square-arrow-right'
  | 'success'
  | 'support'
  | 'twitter-circled'
  | 'world'
  | 'youtube'
  | 'info'
  | 'warning'
  | 'error'
  | 'lightbulb'

interface Props {
  color?: string
  name: Icon
  size?: '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'circle'
}

const props = withDefaults(defineProps<Props>(), {
  color: 'black',
  size: 'md'
})

const classSize = computed(() => {
  let classList
  switch (props.size) {
    case '2xs':
      classList = 'w-3 h-3'
      break
    case 'xs':
      classList = 'w-4 h-4'
      break
    case 'sm':
      classList = 'w-5 h-5'
      break
    case 'lg':
      classList = 'w-8 h-8'
      break
    case 'xl':
      classList = 'w-10 h-10'
      break
    case '2xl':
      classList = 'w-16 h-16'
      break
    case 'circle':
      classList = 'w-5 h-5 xl:w-8 xl:h-8'
      break
    default:
      classList = 'w-6 h-6'
  }
  return classList
})
</script>
